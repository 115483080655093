const externalActions = {
    virustotalIp: {
        type: 'http-get' as const,
        title: 'Lookup on Virustotal (IP)',
        fieldNames: ['source_ip', 'destination_ip', 'host_ip', 'network_forwarded_ip'],
        options: {
            linkTemplate: 'https://www.virustotal.com/gui/ip-address/{field_value}/detection'
        }
    },
    virustotalHash: {
        type: 'http-get' as const,
        title: 'Lookup on Virustotal (Hash)',
        fieldNames: ['hash_md5', 'hash_sha1', 'hash_sha256'],
        options: {
            linkTemplate: 'https://www.virustotal.com/gui/file/{field_value}/detection'
        }
    },
    alienvaultHash: {
        type: 'http-get' as const,
        title: 'Lookup on Alienvault OTX (Hash)',
        fieldNames: ['hash_md5', 'hash_sha1', 'hash_sha256', 'hash_imphash'],
        options: {
            linkTemplate: 'https://otx.alienvault.com/indicator/file/{field_value}/'
        }
    },
    hybridanalysisImpHash: {
        type: 'http-get' as const,
        title: 'Lookup on Hybrid Analysis (Imphash)',
        fieldNames: ['hash_imphash'],
        options: {
            linkTemplate: 'https://www.hybrid-analysis.com/search?query=imphash%3A%22{field_value}%22'
        }
    },
    malshare: {
        type: 'http-get' as const,
        title: 'Lookup on Malshare (Hash)',
        fieldNames: ['hash_md5', 'hash_sha1', 'hash_sha256', 'hash_imphash'],
        options: {
            linkTemplate: 'https://malshare.com/search.php?query={field_value}'
        }
    },
    robtexIp: {
        type: 'http-get' as const,
        title: 'Lookup on Robtex',
        fieldNames: ['source_ip', 'destination_ip', 'host_ip', 'network_forwarded_ip'],
        options: {
            linkTemplate: 'https://www.robtex.com/ip-lookup/{field_value}'
        }
    },
    ipWatchlist: {
        type: 'lookup-table' as const,
        options: {
            fieldType: 'ip'
        }
    },
    userWatchlist: {
        type: 'lookup-table' as const,
        options: {
            fieldType: 'user'
        }
    },
    hashWatchlist: {
        type: 'lookup-table' as const,
        options: {
            fieldType: 'hash'
        }
    }
};
export default externalActions;
